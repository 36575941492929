export const northAmericaCountries = [
    "CA", // Canada (+1)
    "MX", // Mexico (+52)
    "US" // United States (+1)
];
export const asiaCountries = [
    "CN", // China (+86)
    "RU", // Russia (+7)
    "JP", // Japan (+81)
    "SA", // Saudi Arabia (+966)
    "AE", // United Arab Emirates (+971)
    "HK" // Hong Kong (+852)
];
export const europeCountries = [
    "AL", // Albania (+355)
    "AD", // Andorra (+376)
    "AT", // Austria (+43)
    "BY", // Belarus (+375)
    "BE", // Belgium (+32)
    "BA", // Bosnia and Herzegovina (+387)
    "BG", // Bulgaria (+359)
    "HR", // Croatia (+385)
    "CY", // Cyprus (+357)
    "CZ", // Czech Republic (+420)
    "DK", // Denmark (+45)
    "EE", // Estonia (+372)
    "FO", // Faroe Islands (+298)
    "FI", // Finland (+358)
    "AX", // Åland Islands (+358)
    "FR", // France (+33)
    "DE", // Germany (+49)
    "GI", // Gibraltar (+350)
    "GR", // Greece (+30)
    "GG", // Guernsey (+44)
    "JE", // Jersey (+44)
    "HU", // Hungary (+36)
    "IS", // Iceland (+354)
    "IE", // Ireland (+353)
    "IM", // Isle of Man (+44)
    "IT", // Italy (+39)
    "XK", // Kosovo (+383)
    "LV", // Latvia (+371)
    "LI", // Liechtenstein (+423)
    "LT", // Lithuania (+370)
    "LU", // Luxembourg (+352)
    "MT", // Malta (+356)
    "MD", // Moldova (+373)
    "MC", // Monaco (+377)
    "ME", // Montenegro (+382)
    "NL", // Netherlands (+31)
    "NO", // Norway (+47)
    "PL", // Poland (+48)
    "PT", // Portugal (+351)
    "MK", // North Macedonia (+389)
    "RO", // Romania (+40)
    "SM", // San Marino (+378)
    "RS", // Serbia (+381)
    "SK", // Slovakia (+421)
    "SI", // Slovenia (+386)
    "ES", // Spain (+34)
    "SE", // Sweden (+46)
    "CH", // Switzerland (+41)
    "UA", // Ukraine (+380)
    "GB", // United Kingdom (+44)
    "VA" // Vatican City (+379)
];
export const southAmericaCountries = [
    "AR", // Argentina (+54)
    "BO", // Bolivia (+591)
    "BR", // Brazil (+55)
    "CL", // Chile (+56)
    "CO", // Colombia (+57)
    "EC", // Ecuador (+593)
    "FK", // Falkland Islands (+500)
    "GF", // French Guiana (+594)
    "GY", // Guyana (+592)
    "PY", // Paraguay (+595)
    "PE", // Peru (+51)
    "SR", // Suriname (+597)
    "UY", // Uruguay (+598)
    "VE" // Venezuela (+58)
];
export const africaCountries = [
    "EG", // Egypt (+20)
    "ZA" // South Africa (+27)
];
export const oceaniaCountries = [
    "AU", // Australia (+61)
    "CC", // Cocos (Keeling) Islands (+61)
    "CX" // Christmas Island (+61)
];
export const allowedCountries = [
    ...northAmericaCountries,
    ...asiaCountries,
    ...europeCountries,
    ...southAmericaCountries,
    ...africaCountries,
    ...oceaniaCountries
];